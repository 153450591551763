import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceUrl } from '../utils/resource-url';
import { Observable } from 'rxjs';
import { RoleResponse } from '../model/response/role-response';
import { RoleStatusRequest } from '../model/request/role-status-req';
import { UserRoleRequest } from '../model/request/user-role-req';
import { BaseResponse } from '../model/response/base-response';

@Injectable({
  providedIn: 'root'
})
export class ManageRoleService {

  constructor(private httpClient : HttpClient, private resourceUrl : ResourceUrl) { }

  public getAllRoles(page:number, size:number, status:String) {
    return this.httpClient.get(`${this.resourceUrl.userRoles}?page=${page}&size=${size}&status=${status}`)
  }

  public getUserGroupRoles(grpId:number, page:number, size:number) {
    const url = `${this.resourceUrl.userRoles}/group/${grpId}?page=${page}&size=${size}`
    return this.httpClient.get(url)
  }

  public getRoleById(roleId:number) {
    return this.httpClient.get(`${this.resourceUrl.userRoles}/${roleId}`)
  }

  public createUserRole(req: UserRoleRequest) {
    return this.httpClient.post(`${this.resourceUrl.userRoles}`, req)
  }

  public updateUserRole(req: UserRoleRequest) {
    return this.httpClient.put(`${this.resourceUrl.userRoles}`, req)
  }

  public updateRoleStatus(req: RoleStatusRequest) {
    return this.httpClient.put(`${this.resourceUrl.userRoles}/status`, req);
  }

}
