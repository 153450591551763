export class ResourceUrl {
  //Login
  userLogin = base + 'auth/userLogin';

  //Permissions
  permissions = base + 'permission'

  //servicePermissions
  getService = base + 'service'

  //Groups
  userGroups = base + 'userGroup'

  //Role
  userRoles = base + 'userRole'

  //User
  workers = base + 'worker'

  // Worker Roles
  workerRoles = base + 'worker-role'
}

//const base = 'http://13.210.192.226:8080/';

const base = 'https://api.uam.sustech.net.au/';

// const base = "http://ec2-13-127-88-19.ap-south-1.compute.amazonaws.com:8080/v1/";

//const base = "http://0526774d8694.ngrok.io/v1/";
