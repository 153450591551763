import { Component, OnInit } from '@angular/core';
import { ManageGroupService } from '../../../services/manage-group.service';
import { PermissionService } from '../../../services/permission.service';
import { UserGroupRequest } from 'src/app/model/request/user-group-req';
import { GroupStatusRequest } from 'src/app/model/request/group-status-req';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage-group',
  templateUrl: './manage-group.component.html',
  styleUrls: ['./manage-group.component.css'],
})
export class ManageGroupComponent implements OnInit {

  constructor(private groupService: ManageGroupService,
              private permService: PermissionService) {}

  showCreateGroup = false;
  showUpdateGroup = false;
  selectedGroup;
  selectedGroupPerms = [];
  groupList = [];
  permRes = [];
  selectedPerms: number[] = [];
  permsToDelete: number[] = [];
  permsToAdd: number[] = []

  // Pagination
  pageIndex: number = 0;
  pageSize: number = 10;
  totalElements: 0;

  ngOnInit(): void {
    this.loadUserGroups()
    this.loadAllPermissions()
  }

  loadUserGroups() {
    this.groupService
      .getAllGroups(this.pageIndex, this.pageSize, '')
      .subscribe((res: any) => {
          this.groupList = res.content
          this.updatePagination(res)
      })
  }

  loadAllPermissions() {
    this.permService
        .getAllPermissionsGrouped()
        .subscribe((res:any) => {
            this.permRes = res
        })
  }

  handlePage(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize
    this.loadUserGroups()
  }

  updatePagination(res) {
    this.pageIndex = res.number;
    this.pageSize = res.size;
    this.totalElements = res.totalElements; 
  }

  onSelectDeselectPerm(permId: number) {
    if (this.selectedPerms.includes(permId))
        this.selectedPerms.splice(this.selectedPerms.indexOf(permId), 1)
    else
        this.selectedPerms.push(permId);
  }

  onSelectDeselectPermUpdate(permId: number) {
    if (this.selectedPerms.includes(permId)) {
      if (this.permsToAdd.includes(permId))
        this.permsToAdd.splice(this.permsToAdd.indexOf(permId),1)
      if (!this.permsToDelete.includes(permId) && this.selectedGroupPerms.includes(permId))
        this.permsToDelete.push(permId);

      this.selectedPerms.splice(this.selectedPerms.indexOf(permId),1)
    } 
    else {
      if (this.permsToDelete.includes(permId))
        this.permsToDelete.splice(this.permsToDelete.indexOf(permId),1)
      if (!this.permsToAdd.includes(permId) && !this.selectedGroupPerms.includes(permId))
        this.permsToAdd.push(permId)
        
      this.selectedPerms.push(permId)
    }
  }

  onExpandSubList(expIcon: HTMLElement, servId: number) {
    const subList = document.getElementById('' + servId)
    if (expIcon.innerText == 'expand_more') {
        expIcon.innerText = 'expand_less'
        subList.style.display = 'none'
    } else {
        expIcon.innerText = 'expand_more'
        subList.style.display = 'block'
    }
  }
  
  createGroup(grbName: string) {
    if (grbName.length > 0) {
        const req = new UserGroupRequest();
        req.groupName = grbName
        req.permList = this.selectedPerms

        this.groupService
        .createUserGroup(req)
        .subscribe(res => {
            this.toggleCreateModal(false)
            this.loadUserGroups()
        })
    }
  }

  updateGroup(grbName: string) {
    const id = this.selectedGroup.userGroup.groupId
    const req = new UserGroupRequest();
    req.groupId = id;
    req.groupName = grbName;
    req.permList = this.permsToAdd;
    req.deletedPerms = this.permsToDelete;
    const delLen = this.permsToDelete.length
    let conf = false
    if ( delLen > 0) {
      if (confirm("Be careful! Remove " + delLen + " permissions from all the roles under " + this.selectedGroup.userGroup.groupName + "?")) {
        conf = true
      }
    }
    if (conf == true || delLen == 0) {
      this.groupService
      .updateUserGroup(req)
      .subscribe(res => {
          this.toggleUpdateModal(false)
          this.loadUserGroups()
      })
    }
  }

  onShowUpdateGroup(grbId: number) {
    this.groupService
        .getUserGroupDetails(grbId)
        .subscribe((res: any) => {
            this.selectedGroup = res
            this.selectedGroupPerms = Array.from(res.permList)
            this.selectedPerms = res.permList
            this.showUpdateGroup = true;
        })
  }

  handleCheckPerm(permId: number): boolean {
    return this.selectedPerms.includes(permId)
  }

  toggleUpdateModal(vis: boolean) {
    this.showUpdateGroup = vis
    this.permsToAdd = []
    this.permsToDelete = []
    this.selectedPerms = []
  }

  toggleCreateModal(vis: boolean) {
    this.showCreateGroup = vis
    this.permsToAdd = []
    this.permsToDelete = []
    this.selectedPerms = []
  }

  changeGroupStatus(groupId: number, event: any) {
    const req = new GroupStatusRequest();
    req.groupId = groupId;
    req.active = event.checked;
    this.groupService.changeGroupStatus(req)
      .subscribe(res => console.log(res))
  }
}
