import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoleMappingRequest } from 'src/app/model/request/user-role-mapping-req';
import { UserRoleResponse } from 'src/app/model/response/user-role-response';
import { ManageRoleService } from 'src/app/services/manage-role.service';
import { ManageUserService } from 'src/app/services/manage-user.service';
import { UserRoleMappingService } from 'src/app/services/user-role-mapping.service';

@Component({
  selector: 'app-edit-user-roles',
  templateUrl: './edit-user-roles.component.html',
  styleUrls: ['./edit-user-roles.component.css']
})
export class EditUserRolesComponent implements OnInit {

  workerRoles: UserRoleResponse[] = [];
  workerId: number;
  workerDetails: any;
  roleList: any[] = []

  constructor(private route: ActivatedRoute,
              private router: Router,
              private roleService: ManageRoleService,
              private workerService: ManageUserService,
              private workerRoleService: UserRoleMappingService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.workerId = params['workerId'];
      this.loadWorkerRoles();
      this.loadWorkerDetails();
    });
    this.loadAllRoles();
  }

  changeWorkerRoleStatus(roleId:number, workerId: number, event: any) {
    const req = new UserRoleMappingRequest();
    req.roleId = roleId;
    req.workerId = workerId;
    req.active = event.checked;
    this.workerRoleService.updateWorkerRoleMapping(req)
      .subscribe((res: any) => {
        this.snackBar.open(res.message, 'close')
      })
  }

  loadWorkerRoles() {
    this.workerRoleService.getWorkerRoles(this.workerId)
        .subscribe((res: any) => this.workerRoles = res.data)
  }
 
  loadAllRoles() {
    this.roleService
      .getAllRoles(0, 1000, "ACTIVE")
      .subscribe((res: any) => {
          this.roleList = res.content
    })
  }

  addRole(roleId: string) {
    const req = new UserRoleMappingRequest();
    req.active = true;
    req.roleId = Number.parseInt(roleId);
    req.workerId = this.workerId;
    this.workerRoleService.addWorkerRoleMapping(req)
      .subscribe((res: any) => {
        this.loadWorkerRoles()
        this.snackBar.open(res.message, 'close')
      })
  }

  loadWorkerDetails() {
    this.workerService.getUserById(this.workerId)
      .subscribe(res => {
        if (res)
          this.workerDetails = res
        else
          this.router.navigate(['access-management']);
      })
  }

  deleteWorkerRole(workerId: number, roleId: number) {
    this.workerRoleService.deleteWorkerRoleMapping(workerId, roleId)
      .subscribe((res: any) => {
        this.snackBar.open(res.message, 'close');
        this.loadWorkerRoles();
      })
  }

}
