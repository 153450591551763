import { Component, OnInit } from '@angular/core';
import { RoleStatusRequest } from 'src/app/model/request/role-status-req';
import { UserRoleRequest } from 'src/app/model/request/user-role-req';
import { ManageGroupService } from 'src/app/services/manage-group.service';
import { ManageRoleService } from 'src/app/services/manage-role.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-manage-role',
  templateUrl: './manage-role.component.html',
  styleUrls: ['./manage-role.component.css'],
})
export class ManageRoleComponent implements OnInit {

  constructor(private roleService: ManageRoleService,
              private permService: PermissionService,
              private groupService: ManageGroupService) {}

  showCreateRole = false;
  showUpdateRole = false;
  selectedRole;
  selectedRolePerms = [];
  roleList = [];
  groupList = [];
  permList = [];
  selectedGroupId = -1;
  selectedPerms: number[] = [];
  permsToDelete: number[] = [];
  permsToAdd: number[] = []

  // Pagination
  pageIndex: number = 0;
  pageSize: number = 10;
  totalElements: 0;

  ngOnInit(): void {
    this.loadUserRoles(this.selectedGroupId)
    this.loadAllGroups()
  }

  loadAllRoles() {
    this.roleService
      .getAllRoles(this.pageIndex, this.pageSize, '')
      .subscribe((res: any) => {
          this.roleList = res.content
          this.updatePagination(res)
    })
  }

  loadPermissions(grpId: number) {
    this.permService
        .getGroupPermissions(grpId)
        .subscribe((res:any) => {
            this.permList = res
        })
  }

  loadUserRoles(grpId: number) {
    if (grpId == -1)
      this.loadAllRoles()
    else
      this.loadGroupRoles(grpId);

    this.selectedGroupId = grpId
  }

  loadGroupRoles(grpId: number) {
    this.roleService
    .getUserGroupRoles(grpId, this.pageIndex, this.pageSize)
    .subscribe((res:any) => {
      this.roleList = res.content
      this.updatePagination(res)
    })
  }

  loadAllGroups() {
    // TODO: Not Fixed Loading
    this.groupService
        .getAllGroups(0, 1000, 'ACTIVE')
        .subscribe((res:any) => {
          this.groupList = res.content
          this.loadPermissions(this.groupList[0].groupId)
        })
  }

  handlePage(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize
    this.loadUserRoles(this.selectedGroupId)
  }

  updatePagination(res) {
    this.pageIndex = res.number;
    this.pageSize = res.size;
    this.totalElements = res.totalElements; 
  }

  onSelectDeselectPerm(permId: number) {
    if (this.selectedPerms.includes(permId))
        this.selectedPerms.splice(this.selectedPerms.indexOf(permId), 1)
    else
        this.selectedPerms.push(permId);
  }

  onSelectDeselectPermUpdate(permId: number) {
    if (this.selectedPerms.includes(permId)) {
      if (this.permsToAdd.includes(permId))
        this.permsToAdd.splice(this.permsToAdd.indexOf(permId),1)
      if (!this.permsToDelete.includes(permId) && this.selectedRolePerms.includes(permId))
        this.permsToDelete.push(permId);

      this.selectedPerms.splice(this.selectedPerms.indexOf(permId),1)
    } 
    else {
      if (this.permsToDelete.includes(permId))
        this.permsToDelete.splice(this.permsToDelete.indexOf(permId),1)
      if (!this.permsToAdd.includes(permId) && !this.selectedRolePerms.includes(permId))
        this.permsToAdd.push(permId)
        
      this.selectedPerms.push(permId)
    }
  }

  onExpandSubList(expIcon: HTMLElement, servId: number) {
    const subList = document.getElementById('' + servId)
    if (expIcon.innerText == 'expand_more') {
        expIcon.innerText = 'expand_less'
        subList.style.display = 'none'
    } else {
        expIcon.innerText = 'expand_more'
        subList.style.display = 'block'
    }
  }
  
  createRole(grpId:number, roleName: string) {
    if (roleName.length > 0) {
        const req = new UserRoleRequest();
        req.groupId = grpId
        req.roleName = roleName
        req.permList = this.selectedPerms
        this.roleService
            .createUserRole(req)
            .subscribe(
              (res) => {
                this.loadUserRoles(this.selectedGroupId)
                this.toggleCreateRole(false)},
              (error) => {
                this.toggleCreateRole(false)
            })
    }
  }

  updateRole(roleName: string) {
    if (roleName.length > 0) {
      const roleId = this.selectedRole.role.roleId
      const grpId = this.selectedRole.role.groupId
      const req = new UserRoleRequest();
      req.groupId = grpId;
      req.roleId = roleId;
      req.roleName = roleName;
      req.permList = this.permsToAdd;
      req.deletedPerms = this.permsToDelete;
      this.roleService
        .updateUserRole(req)
        .subscribe(res => {
            this.loadUserRoles(this.selectedGroupId)
            this.toggleUpdateRole(false)
          }, 
          (error) => {
            this.toggleUpdateRole(false)
        })
    }
  }

  onShowUpdateRole(grpId:number, roleId: number) {
    this.permService
        .getGroupPermissions(grpId)
        .subscribe((res:any) => {
            this.permList = res
            this.getRoleDetails(roleId)
        })
  }

  getRoleDetails(roleId: number) {
    this.roleService
    .getRoleById(roleId)
    .subscribe((res: any) => {
        this.selectedRole = res
        this.selectedRolePerms = Array.from(res.permList)
        this.selectedPerms = res.permList
        this.showUpdateRole = true;
    })
  }

  handleCheckPerm(permId: number): boolean {
    return this.selectedPerms.includes(permId) || this.permsToAdd.includes(permId)
  }

  toggleCreateRole(show: boolean) {
    if (this.groupList.length > 0)
      this.loadPermissions(this.groupList[0].groupId)
    this.showCreateRole = show
    this.permsToAdd = []
    this.permsToDelete = []
    this.selectedPerms = []
  }

  toggleUpdateRole(show: boolean) {
    this.showUpdateRole = show
    this.permsToAdd = []
    this.permsToDelete = []
    this.selectedPerms = []
  }

  onChangeUserGroup(grpId: number) {
    this.pageIndex = 0
    this.loadUserRoles(grpId)
  }

  changeRoleStatus(roleId: number, event: any) {
    const req = new RoleStatusRequest();
    req.roleId = roleId;
    req.active = event.checked;
    if (confirm('All the users under this role will be disabled!'))
      this.roleService.updateRoleStatus(req)
          .subscribe(res => console.log(res))
  }

}
